@import "styles/_colors.scss"; @import "styles/_variables.scss"; @import "styles/_cmixins.scss";
@import "../resets.module.scss";

.input {
  font-family: $primary_font;
  width: 100%;
  .header {
    height: 100%;
    // display: flex;
    // label {
    //   justify-content: flex-start;
    // }

    // .switch__clr {
    //   justify-content: space-between;
    // }
  }
  .inputIcon {
    position: absolute;
    top: 30%;
    right: 16px;
  }
  .inputIconText {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #666666;
  }
  .labeltwo {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #666666;
    margin: 0 0 12px 0;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  .icon {
    position: absolute;
  }
}
